<template>
  <div class="detail">
    <!-- <pageHead /> -->
    <div class="img" v-if="id==34">
    </div>

    <div class="img1" v-if="id==33">
    </div>

    <div class="img2" v-if="id==36">
    </div>

    <div class="bar" v-if="id == 34">
      <div class="bar_left">
        <div class="bar_left_title">水司公告</div>
        <ul>
          <!-- <router-link :to="{path:'/detail',query:{nc:101034001}}"> -->
            <li class="bar_left_item" :class="{ active: show == 1 }" @click="(show = 1), (currentPage = 1),(showid = '')">
              工程公示
            </li>
          <!-- </router-link> -->
          <!-- <router-link :to="{path:'/detail',query:{nc:101034002}}"> -->
            <li class="bar_left_item" :class="{ active: show == 2 }" @click="(show = 2), (currentPage = 1),(showid = '')">
              水质公告
            </li>
            <li class="bar_left_item" :class="{ active: show == 3 }" @click="(show = 3), (currentPage = 1),(showid = '')">
              优化营商环境
            </li>
          <!-- </router-link> -->
          <!-- <router-link :to="{ path: '/detail', query: { nc: 101034004 } }"> -->
            <li class="bar_left_item" :class="{ active: show == 4 }" @click="(show = 4), (currentPage = 1), (showid = '')">
              停水公告
            </li>
          <!-- </router-link> -->
          <!-- <router-link :to="{path:'/detail',query:{nc:101034005}}"> -->
            <li class="bar_left_item" :class="{ active: show == 5 }" @click="(show = 5), (currentPage = 1),(showid = '')">
              通知公告
            </li>
          <!-- </router-link> -->
        </ul>
      </div>
      <div class="bar_right" v-if="show == 1">
        <div class="bar_right_title flex">
          <div class="title_gg">工程公示</div>
          <div class="title_wz">当前位置：水司公告 > 工程公示</div>
        </div>
        <div v-if="showid" style="width: 800px; margin: 0 auto;">
          <div class="tsmap">
            <div class="tsmap_title">施工区域地图</div>
            <baidu-map class="map"
                       :center="ExcellentGPS"
                       :zoom='17'
                       :scroll-wheel-zoom='true'>
              <bm-marker :position="ExcellentGPS" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
              </bm-marker>
            </baidu-map>
          </div>
          <div class="constructionAddress">{{nowData1[0].compoundName}}</div>
          <div class="construction_item flex">
            <div>工程类别</div>
            <div>{{nowData1[0].gongchengzhonglei}}</div>
          </div>
          <div class="construction_item flex">
            <div>年份</div>
            <div>{{nowData1[0].yearDate}}</div>
          </div>
          <div class="construction_item flex">
            <div>是否已纳入</div>
            <div>已纳入</div>
          </div>
          <div class="construction_item flex">
            <div>目前进度</div>
            <div>{{ nowData1[0].currentStatus }}</div>
          </div>
          <div class="construction_item flex">
            <div>下一阶段计划</div>
            <div>{{ nowData1[0].nextPlan }}</div>
          </div>
          <div class="construction_item flex">
            <div>预计工程完成时间</div>
            <div>{{ nowData1[0].workOver }}</div>
          </div>
        </div>

        <div class="bar_right_txt flex" style="flex-direction: column" v-else>
          <div v-if="waterExcellent1.length">
            <div class="bar_right_item" v-for="(item, index) in waterExcellent1" :key="index">
            <span id="time">{{ item.createTime | Date }}</span>
            <router-link
                :to="{ path: '/detail', query: { nc: 101034001, id: item.id } }"
                target="_blank">
              <span style="font-size: 16px">•&nbsp;{{ item.compoundName }}工程公示</span>
            </router-link>
          </div>
          <!-- 分页功能 -->
          <div class="flex" style="margin-bottom: 10px;width: 820px">
            <el-button size="mini" @click="gotoFirst()">首页</el-button>
            <el-pagination
                background
                :page-size="15"
                :current-page="currentPage"
                layout="prev, pager, next"
                :total="waterExcellent.length"
                @current-change="change"
                prev-text="上一页"
                next-text="下一页"
            >
            </el-pagination>
            <el-button size="mini" @click="gotoEnd(waterExcellent)">末页</el-button>
          </div>
          </div>  
          <el-empty v-else></el-empty>
        </div>
      </div>

      <div class="bar_right" v-if="show == 2">
        <div class="bar_right_title flex">
          <div class="title_gg">水质公告</div>
          <div class="title_wz">当前位置：水司公告 > 水质公告</div>
        </div>
        <div v-if="showid">
          <div v-if="report_morning.type=='morning'">
            <h1 v-if="report_morning.type!='dayList'" style="text-align:center;" >{{report_morning.date}}水质公告(上午)</h1>
            <table v-if="report_morning.report" style="width:95%;text-align: center;margin:15px auto;border: 1px solid #eee;">
              <thead style="font-weight: bold;background-color: #064a93;color: #fff;">
                <tr>
                  <td rowspan="2">检测项目</td>
                  <td rowspan="2">参考标准</td>
                  <td colspan="3">水源水</td>
                  <td colspan="5">出厂水</td>
                </tr>
                <tr>
                  <td>08:00</td>
                  <td>10:00</td>
                  <td>12:00</td>
                  <td>08:00</td>
                  <td>09:00</td>
                  <td>10:00</td>
                  <td>11:00</td>
                  <td>12:00</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in report_morning.report" :key="index">
                  <td>{{item.name}}</td>
                  <td>{{item.standard}}</td>
                  <td>{{item.Source_8}}</td>
                  <td>{{item.Source_10}}</td>
                  <td>{{item.Source_12}}</td>
                  <td>{{item.Ex_8}}</td>
                  <td>{{item.Ex_9}}</td>
                  <td>{{item.Ex_10}}</td>
                  <td>{{item.Ex_11}}</td>
                  <td>{{item.Ex_12}}</td>
                </tr>
              </tbody>
            </table>
            <div v-if="report_morning.imgUrl">
              <img  :src="report_morning.imgUrl" alt="">
            </div>
          </div>
          <div v-if="report_afternoon.type=='afternoon'">
            <h1 v-if="report_morning.type!='dayList'" style="text-align:center;">{{report_afternoon.date}}水质公告(下午)</h1>
            <table v-if="report_afternoon.report" style="width:95%;text-align: center;margin: 15px auto;border: 1px solid #eee;">
              <thead style="font-weight: bold;background-color: #064a93;color: #fff;">
                <tr>
                  <td rowspan="2">检测项目</td>
                  <td rowspan="2">参考标准</td>
                  <td colspan="3">水源水</td>
                  <td colspan="4">出厂水</td>
                </tr>
                <tr>
                  <td>15:00</td>
                  <td>16:00</td>
                  <td>18:00</td>
                  <td>15:00</td>
                  <td>16:00</td>
                  <td>17:00</td>
                  <td>18:00</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in report_afternoon.report" :key="index">
                  <td>{{item.name}}</td>
                  <td>{{item.standard}}</td>
                  <td>{{item.Source_15}}</td>
                  <td>{{item.Source_16}}</td>
                  <td>{{item.Source_18}}</td>
                  <td>{{item.Ex_15}}</td>
                  <td>{{item.Ex_16}}</td>
                  <td>{{item.Ex_17}}</td>
                  <td>{{item.Ex_18}}</td>
                </tr>
              </tbody>
            </table>
            <div v-if="report_afternoon.imgUrl">
              <img  :src="report_afternoon.imgUrl" alt="">
            </div>
          </div>
          <div v-if="report_days.type=='dayList'">
            <div v-if="report_days.imgUrl">
              <img  :src="report_days.imgUrl" alt="" style="width:100%">
            </div>
          </div>
        </div>
        <div v-else>
          <div class="bar_right_txt">
            <div v-if="waterNews.length" style="height:500px;overflow-y:auto;">
              <div class="flex" style="flex-direction: column">
                <div class="bar_right_item" v-for="(item,index) in waterNews" :key="index" >
                  <router-link :to="{ path: '/detail', query: { nc: 101034002, id: item.createTime} }" target="_blank">
                  <div style="display:flex">
                    <span style="font-size: 16px;flex:1">•&nbsp;{{ item.date }}水质信息</span>
                    <span>{{ item.createTime}}</span>
                    </div>
                  </router-link>
                </div>
              </div>
              <!-- 分页功能 -->
              <!-- <div class="flex" style="margin-top: 10px; margin-left: -15px;">
                <el-button size="mini" @click="gotoFirst()">首页</el-button>
                <el-pagination
                    background
                    :page-size="15"
                    :current-page="currentPage"
                    layout="prev, pager, next"
                    :total="waterNews.length"
                    @current-change="change"
                    prev-text="上一页"
                    next-text="下一页"
                >
                </el-pagination>
                <el-button size="mini" @click="gotoEnd(waterNews)">末页</el-button>
            </div> -->
            </div>
            <el-empty v-else></el-empty>
          </div>
        </div>

      </div>
      <div class="bar_right" v-if="show == 3">
        <div class="bar_right_title flex">
          <div class="title_gg">优化营商环境</div>
          <div class="title_wz">当前位置：水司公告 > 优化营商环境</div>
        </div>
        <div v-if="showid" style="width: 800px; margin: 0 auto;">
         
        </div>

        <div class="bar_right_txt flex" style="flex-direction: column" v-else>
          <div v-if="optimizeTheBusinessEnv.length">
            <div class="bar_right_item" v-for="(item, index) in optimizeTheBusinessEnv" :key="index" @click="linkTo(item.url)">
              <span id="time">{{item.createTime}}</span>
              <span style="font-size: 16px">•&nbsp;{{ item.title }}</span>
            </div>
          <!-- 分页功能 -->
          <div class="flex" style="margin-bottom: 10px;width: 820px">
            <el-button size="mini" @click="gotoFirst()">首页</el-button>
            <el-pagination
                background
                :page-size="15"
                :current-page="currentPage"
                layout="prev, pager, next"
                :total="optimizeTheBusinessEnv.length"
                @current-change="change"
                prev-text="上一页"
                next-text="下一页"
            >
            </el-pagination>
            <el-button size="mini" @click="gotoEnd(waterExcellent)">末页</el-button>
          </div>
          </div>  
          <el-empty v-else></el-empty>
        </div>
      </div>
      <div class="bar_right" v-if="show == 4">
        <div class="bar_right_title flex">
          <div class="title_gg">停水公告</div>
          <div class="title_wz">当前位置：水司公告 > 停水公告</div>
        </div>

<!--        显示当前showid对应的数据-->
        <div style="padding: 15px" v-if="showid">
          <div class="d_title" style="color: black">{{ nowData[0].title }}</div>
          <div class="d_time">发表时间：{{ nowData[0].createtime }}</div>
          <div class="d_item">
            【停水时间】：{{ nowData[0].tstime }} 至 {{ nowData[0].hftime }}
          </div>
          <div class="d_item">【停水主管径】：{{ nowData[0].tspipe }}</div>
          <div class="d_item">【停水原因】：{{ nowData[0].tscause }}</div>
          <div class="d_item">【停水地点】：{{ nowData[0].tsscope }}</div>
          <div class="d_item">【停水客户】：{{ nowData[0].tsclient }}</div>
          <!-- <div class="d_item">【服务热线】：{{ nowData[0].tel }}</div> -->
          <div class="tsmap">
            <div class="tsmap_title">停水区域地图</div>
            <baidu-map class="map"
                       :center="GPS[0]"
                       :zoom='16'
                       :scroll-wheel-zoom='true'>
              <bm-polygon :path="GPS" stroke-color="blue" :stroke-opacity="0.5"
                          :stroke-weight="2" :editing="true"
                          strokeColor="#F1282F" fillColor="#F6DAD8"
                          :points="GPS" shape="BMAP_POINT_SHAPE_STAR" color="red"
                          size="BMAP_POINT_SIZE_SMALL">
                <!--                <bm-point-collection :points="GPS" shape="BMAP_POINT_SHAPE_STAR" color="red" size="BMAP_POINT_SIZE_SMALL">-->
                <!--                </bm-point-collection>-->
              </bm-polygon>
            </baidu-map>
          </div>
        </div>

<!--        显示所有的数据-->
        <div v-else>
          <div class="r_news">
            <div class="t_title">{{ cutWaterlist[0].title }}</div>
            <div class="t_txt">
              <div>
                【停水时间】：{{ cutWaterlist[0].tstime }} 至
                {{ cutWaterlist[0].hftime }}
              </div>
              <div>【停水主管径】：{{ cutWaterlist[0].tspipe }}</div>
              <div>【停水原因】：{{ cutWaterlist[0].tscause }}</div>
              <div>【停水地点】：{{ cutWaterlist[0].tsscope }}</div>
              <div>【停水客户】：{{ cutWaterlist[0].tsclient }}</div>
              <div>【服务热线】：{{ cutWaterlist[0].tel }}</div>
            </div>
          </div>
          <div class="bar_right_txt flex" style="flex-direction: column">
            <div class="bar_right_item" v-for="(item, index) in cutWater1" :key="index">
              <span id="time">{{ item.createtime | Date }}</span>
              <router-link
                  :to="{ path: '/detail', query: { nc: 101034004, id: item.id } }"
                  target="_blank">
                <span id="news_nav" style="font-size: 16px">•&nbsp;{{ item.title }}</span>
              </router-link>
            </div>
          </div>
          <!-- 分页功能 -->
          <div class="flex" style="margin-bottom: 10px;width: 820px">
            <el-button size="mini" @click="gotoFirst()">首页</el-button>
            <el-pagination
                background
                :page-size="15"
                :current-page="currentPage"
                layout="prev, pager, next"
                :total="cutWaterlist.length"
                @current-change="change"
                prev-text="上一页"
                next-text="下一页"
            >
            </el-pagination>
            <el-button size="mini" @click="gotoEnd(cutWaterlist)">末页</el-button>
          </div>
        </div>
      </div>

      <div class="bar_right" v-if="show == 5">
        <div class="bar_right_title flex">
          <div class="title_gg">通知公告</div>
          <div class="title_wz">当前位置：水司公告 > 通知公告</div>
        </div>
        <div class="bar_right_txt flex" style="flex-direction: column">
          <div class="bar_right_item" v-for="(item, index) in waterAnnouncement1" :key="index">
            <span id="time">{{ item.createtime | Date }}</span>
            <span style="font-size: 16px" @click="openUrl1(item)">•&nbsp;{{ item.title }}</span>
          </div>
          <!-- 分页功能 -->
          <div class="flex" style="margin-bottom: 10px;width: 820px">
            <el-button size="mini" @click="gotoFirst()">首页</el-button>
            <el-pagination
                background
                :page-size="15"
                :current-page="currentPage"
                layout="prev, pager, next"
                :total="waterAnnouncement.length"
                @current-change="change"
                prev-text="上一页"
                next-text="下一页"
            >
            </el-pagination>
            <el-button size="mini" @click="gotoEnd(waterAnnouncement)">末页</el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="bar" v-if="id == 33">
      <div class="bar_left">
        <div class="bar_left_title">集团动态</div>
        <ul>
          <router-link :to="{ path: '/detail', query: { nc: 101033001 } }">
            <li class="bar_left_item" :class="{ active: show == 1 }" @click="(show = 1)">
              服务承诺
            </li>
          </router-link>
          <router-link :to="{ path: '/detail', query: { nc: 101033002 } }">
            <li class="bar_left_item" :class="{ active: show == 2 }" @click="(show = 2), (currentPage = 1),(showid='')">
              集团新闻
            </li>
          </router-link>
<!--          <li class="bar_left_item" :class="{ active: show == 3 }" @click="show = 3">-->
<!--            企业文化-->
<!--          </li>-->
<!--          <li class="bar_left_item" :class="{ active: show == 4 }" @click="show = 4">-->
<!--            企业歌曲-->
<!--          </li>-->
<!--          <li class="bar_left_item" :class="{ active: show == 5 }" @click="show = 5">-->
<!--            图片库-->
<!--          </li>-->
<!--          <li class="bar_left_item" :class="{ active: show == 6 }" @click="show = 6">-->
<!--            专题活动-->
<!--          </li>-->
        </ul>
      </div>
      <div class="bar_right" v-if="show == 1">
        <div class="bar_right_title flex">
          <div class="title_gg">服务动态</div>
          <div class="title_wz">当前位置：集团动态 > 服务动态</div>
        </div>
        <div class="bar_right_txt flex" style="flex-direction: column">
          <div v-html="waterPromise[0].text"></div>
        </div>
      </div>

      <div class="bar_right" v-if="show == 2">
        <div class="bar_right_title flex">
          <div class="title_gg">集团新闻</div>
          <div class="title_wz">当前位置：集团动态 > 集团新闻</div>
        </div>

<!--        显示所有的数据-->
        <div>
          <div class="bar_right_txt flex" style="flex-direction: column">
            <div class="bar_right_item" v-for="(item, index) in SwNews1" :key="index">
              <span id="time">{{ item.update_time | Date }}</span>
              <span style="font-size: 16px;" @click="openUrl(item)">•&nbsp;{{ item.title }}</span>
            </div>
          </div>
<!--          分页功能-->
          <div class="flex" style="margin-bottom: 10px">
            <el-button size="mini" @click="gotoFirst()">首页</el-button>
            <el-pagination
                background
                :page-size="15"
                :current-page="currentPage"
                layout="prev, pager, next"
                :total="SwNews.length"
                @current-change="change"
                prev-text="上一页"
                next-text="下一页"
            >
            </el-pagination>
            <el-button size="mini" @click="gotoEnd(SwNews)">末页</el-button>
          </div>
        </div>
      </div>

      <div class="bar_right" v-if="show == 3">
        <div class="bar_right_title flex">
          <div class="title_gg">企业文化</div>
          <div class="title_wz">当前位置：集团动态 > 企业文化</div>
        </div>
        <div class="bar_right_txt flex" style="flex-direction: column">
          <div
              class="bar_right_item"
              v-for="(item, index) in wenhua"
              :key="index"
          >
            <span id="time">2017-07-12</span>
            <span id="news_nav">•&nbsp;{{ item }}</span>
          </div>
        </div>
      </div>

      <div class="bar_right" v-if="show == 4">
        <div class="bar_right_title flex">
          <div class="title_gg">企业歌曲</div>
          <div class="title_wz">当前位置：集团动态 > 歌曲</div>
        </div>
      </div>

      <div class="bar_right" v-if="show == 5">
        <div class="bar_right_title flex">
          <div class="title_gg">图片库</div>
          <div class="title_wz">当前位置：集团动态 > 图片库</div>
        </div>
        <div class="r_img">
          <ul>

            <li style="margin: 0px;">
              <a href="javascript:void(0)" target="_blank" title="辉煌30年，桐水杯职工摄影展">
                <img src="img/20171212112622579.jpg"><br>
                辉煌30年，桐水杯职工摄影展</a>
            </li>

            <li>
              <a href="javascript:void(0)" target="_blank" title="第二届职工运动会照片展">
                <img src="img/2017121211230329.jpg"><br>
                第二届职工运动会照片展</a>
            </li>

            <li style="margin: 0px;">
              <a href="javascript:void(0)" target="_blank" title="万德大厦照片集">
                <img src="img/20171212112053125.jpg"><br>
                万德大厦照片集</a>
            </li>

          </ul>
          <div style="clear: both;"></div>
        </div>
      </div>

      <div class="bar_right" v-if="show == 6">
        <div class="bar_right_title flex">
          <div class="title_gg">专题活动</div>
          <div class="title_wz">当前位置：集团动态 > 专题活动</div>
        </div>
        <div class="r_img">
          <ul>

            <li style="margin: 0px;">
              <a href="javascript:void(0)" title="桐庐水务2016年社会责任报告">
                <img src="img/20180108060420130.jpg"><br>
                桐庐水务2016年社会责任报告</a>
            </li>

            <li>
              <a href="javascript:void(0)" title="桐水第一届员工创新大赛">
                <img src="img/20171212031727854.jpg"><br>
                桐水第一届员工创新大赛</a>
            </li>

          </ul>
          <div style="clear: both;"></div>
        </div>
      </div>
    </div>

    <div class="bar" v-if="id == 36">
      <div class="bar_left">
        <div class="bar_left_title">网站说明</div>
        <ul>
          <li class="bar_left_item" :class="{ active: show == 1 }" @click="show = 1">
            友情链接
          </li>
          <li class="bar_left_item" :class="{ active: show == 2 }" @click="show = 2">
            法律声明
          </li>
          <li class="bar_left_item" :class="{ active: show == 3 }" @click="show = 3">
            网站地图
          </li>
          <li class="bar_left_item" :class="{ active: show == 4 }" @click="show = 4">
            联系我们
          </li>
        </ul>
      </div>

      <div class="bar_right" v-if="show == 1">
        <div class="bar_right_title flex">
          <div class="title_gg">友情链接</div>
          <div class="title_wz">当前位置：网站说明 > 友情链接</div>
        </div>
        <div class="bar_right_txt">
          <a href="https://www.waterchina.com/" target="_blank">
            <img
                style="width: 600px; height: 60px; margin-bottom: 30px"
                src=""
            />
          </a>
          <a href="http://www.zhuhai-water.com.cn/" target="_blank">
            <img
                style="width: 600px; height: 60px; margin-bottom: 30px"
                src=""
            />
          </a>
          <a href="http://www.fswater.com/" target="_blank">
            <img
                style="width: 600px; height: 60px; margin-bottom: 30px"
                src=""
            />
          </a>
          <a
              href="http://www.gzwatersupply.com/2010commom/div2010index.html2"
              target="_blank"
          >
            <img
                style="width: 600px; height: 60px; margin-bottom: 30px"
                src=""
            />
          </a>
        </div>
      </div>

      <div class="bar_right" v-if="show == 2">
        <div class="bar_right_title flex">
          <div class="title_gg">法律声明</div>
          <div class="title_wz">当前位置：网站说明 > 法律声明</div>
        </div>
        <div class="bar_right_txt">
          <div>敬请期待</div>
        </div>
      </div>

      <div class="bar_right" v-if="show == 3">
        <div class="bar_right_title flex">
          <div class="title_gg">网站地图</div>
          <div class="title_wz">当前位置：网站说明 > 网站地图</div>
        </div>
        <div class="bar_right_txt">
          <div>敬请期待</div>
        </div>
      </div>

      <div class="bar_right" v-if="show == 4">
        <div class="bar_right_title flex">
          <div class="title_gg">联系我们</div>
          <div class="title_wz">当前位置：网站说明 > 联系我们</div>
        </div>
        <div class="bar_right_txt">
          <div>
            <p>服务热线：82137777</p>
            <p>E-mail:service@waterchina.com</p>
          </div>
        </div>
      </div>
    </div>

    <!-- <pageFoot/> -->
  </div>
</template>
<script>
// 引入百度地图
//引入element组件库
import ElementUI from "element-ui";
// 引入http
import http from "../http/index";
import Vue from "vue";
Vue.use(ElementUI);
// 使用百度地图
//引入底部组件
// import pageFoot from "../../components/pageFoot.vue";
export default {
  name: "detail",
  // props: ["active"],
  data() {
    return {
      report_morning:{},
      report_afternoon:{},
      report_days:{},
      optimizeTheBusinessEnv:[],//优化营商环境
      reportID: this.$route.query,
      // 控制左侧导航栏内容
      id: this.$route.query.nc.slice(4, 6),
      // 控制高亮
      show: this.$route.query.nc.slice(8, 9),
      // 当前需要显示的数据id
      showid: this.$route.query.id,
      // 当前需要显示的数据
      nowData: [],
      // 页码
      currentPage: 1,
      // 停水公告
      cutWaterlist: [],
      // 水务新闻
      SwNews: [],
      // 水质公告日期
      waterNews:[],
      // 当前公告日期
      nowWater:'',
      // 水质公告
      WaterNotice:[],
      // 月份数据
      monthData:[],
      // 上半月数据
      upMonth:[],
      // 下半月数据
      downMonth:[],
      fuwu: [],
      // 停水区域位置经纬度
      GPS:[],
      chuchanglist:[],
      guanwanglist:[],
      showzlTable:true,
      showccTable:false,
      showgwTable:false,
      chuchang:true,
      guanwang:true,
      zonglang:false,
      waterAnnouncement:[],
      waterExcellent:[],
      ExcellentGPS:'',
      nowData1:[],
      waterPromise:[]
    };
  },
  filters: {
    // 返回一个日期  年-月-日
    Date(val) {
      return val.slice(0, 10);
    },
  },
  methods: {
    linkTo(url){
      window.open(url)
    },
    async getOptimizeTheBusinessEnv(){
      this.optimizeTheBusinessEnv = await http.getOptimizeTheBusinessEnv();
    },
    async showList(val){
      this.show=val
      this.currentPage = 1
      this.showid = ''
      this.waterNews=await http.getWaterQualityReport();
      console.log(this.waterNews)
    },
    // 获取水务新闻数据
    async getNewsMsg() {
      try {
        const res = await http.getNewsMsg();
        // console.log("水务news", res);
        this.SwNews = res.rows.sort(this.SortDate('update_time',false));
        // console.log(this.SwNews, "++++++++");
        // console.log(this.showid);
        this.nowData = this.SwNews.filter((r) => r.id == this.showid);
        // console.log(this.nowData)
      } catch (ex) {
        console.log(ex);
      }
    },

    // 获取停水公告数据
    async cutWater() {
      try {
        const res = await http.cutWater();
        // console.log("5555666", res);
        this.cutWaterlist = res.sort(this.SortDate('createtime',false));
        // console.log(this.cutWaterlist, "++++++++");
        // console.log(this.showid);
        this.nowData = this.cutWaterlist.filter((r) => r.id == this.showid);
        // console.log("nowData",this.nowData);
        // console.log(this.nowData.tsradii)
        // if(this.nowData.length>0){
          this.nowData[0].tsradii.split('|').forEach(r=>{
          let Brr = r.split(',')
          this.GPS.push({
            lat:parseFloat(Brr[0]),
            lng:parseFloat(Brr[1])
          })
        })
        // }
        
        // console.log('GGGGG',this.GPS)
      } catch (ex) {
        console.log(ex);
      }
    },

    // 获取水质公告
    async getWater() {
      try {
        const res = await http.getWaterQualityReport();
        this.waterNews = res
        console.log("水质报告6",this.waterNews)
        const reportID = this.$route.query.id;
        console.log("reportID*****",reportID);
        res.forEach(r=>{
          console.log("r",r)
          if(r.createTime==reportID){
            if(r.type==='morning'){
                this.report_morning = r
                console.log("this.report_morning",this.report_morning)
            }else if(r.type==='afternoon'){
                this.report_afternoon = r
                console.log("this.report_afternoon",this.report_afternoon)
            }else if(r.type==='dayList'){
              this.report_days = r
              console.log("this.report_afternoon",this.report_afternoon)
            }
          }
          console.log(r.createTime,reportID)
        })
        // // console.log("shuizhi", res);
        // res.forEach(r=>{
        //   this.waterNews.push(Object.keys(r))
        // })
        // this.nowWater = this.waterNews[this.showid][0]

        // this.WaterNotice = Object.values(res[this.showid]) // 当前数据
        // this.monthData = this.WaterNotice[0][0]
        // this.chuchanglist = this.WaterNotice[0][2]
        // this.guanwanglist = this.WaterNotice[0][3]
        // Object.values(this.WaterNotice[0][1]).map(r=>{
        //   // console.log('123123',r)
        //   r.forEach(item=>{
        //     if (item.updownmonth === 1) {
        //       this.upMonth.push(item)
        //     }else{
        //       this.downMonth.push(item)
        //     }
        //   })
        // })
        // console.log('downMonth',this.downMonth)
        // console.log('upMonth',this.upMonth)
        // console.log(Object.values(this.WaterNotice))
        // this.waterNews = Object.keys(res)
      } catch (ex) {
        console.log(ex);
      }
    },

    //
    async getNotice(){
      try {
        const res = await http.getNotice();
        // console.log("通知公告", res);
        this.waterAnnouncement = res.sort(this.SortDate("createtime",false))
        // this.SwNews = res.rows.sort(this.SortDate('update_time',false));
      } catch (ex) {
        console.log(ex);
      }
    },

    //
    async getExcellent(){
      try {
        const res = await http.getExcellent();
        // console.log("工程", res);
        this.waterExcellent = res.sort(this.SortDate("createtime",false))
        this.nowData1 = this.waterExcellent.filter((r) => r.id == this.showid);
        // console.log(this.showid)
        console.log("nowData",this.nowData1);
        // console.log(this.nowData.tsradii))
        this.ExcellentGPS = {
          lng:parseFloat(this.nowData1[0].lat),
          lat:parseFloat(this.nowData1[0].lng)
        }
        // console.log('gps的地址',this.ExcellentGPS)
      } catch (ex) {
        console.log(ex);
      }
    },

    async getpromise(){
      try {
        const res = await http.getpromise();
        // console.log("承诺", res);
        this.waterPromise = res
        // this.waterAnnouncement = res.sort(this.SortDate("createtime",false))
        // this.SwNews = res.rows.sort(this.SortDate('update_time',false));
      } catch (ex) {
        console.log(ex);
      }
    },
    // <a target="_black" href="https://mp.weixin.qq.com/s/Pj2Kdmvt_O7yKfICK1QyqA" style="display: flex;"><span style="flex: 1 1 0%;">•&nbsp; 从源头到龙头，你用水我们用"心"</span><span id="time">2022-02-19 10:11</span></a>

    //分页切换
    change(val) {
      // console.log(`第${val}页`);
      this.currentPage = val;
    },
    // 回到首页
    gotoFirst() {
      this.currentPage = 1;
    },
    // 去到末页
    gotoEnd(list) {
      this.currentPage = Math.ceil(list.length / 15);
    },
    // 获取当前showid对应的数据
    getData() {
      console.log(this.cutWaterlist.filter((r) => r.id == this.showid));
    },
    // 按时间进行排序算法
    SortDate(time,type){
      //createtime
      return function (a,b){
        let value1 = a[time]
        let value2 = b[time]
        if(type){
          //升序排序
          return Date.parse(value1) - Date.parse(value2)
        }else{
          //降序
          return Date.parse(value2) - Date.parse(value1)
        }
      }
    },
    changeShow(name){
      if(name == 'guanwang'){
        this.guanwang = false,
        this.showgwTable = true
        this.chuchang = true,
        this.showccTable = false
        this.zonglang = true
        this.showzlTable = false
        document.documentElement.scrollTop -=3000
      }else if(name == 'chuchang'){
        this.chuchang = false,
        this.showccTable = true,
        this.guanwang = true,
        this.showgwTable = false
        this.zonglang = true
        this.showzlTable = false
        document.documentElement.scrollTop -=3000
      }else if(name == 'zonglang'){
        this.zonglang = false,
        this.showzlTable = true
        this.guanwang = true
        this.showgwTable = false
        this.chuchang = true
        this.showccTable = false
        document.documentElement.scrollTop -=3000
      }
    },
    openUrl(list){
      // console.log(list)
      // console.log(list.url)
      window.open(list.url)
    },
    // openUrl1(list){
    //   if(list.urltype == 2){
    //     window.open(list.url)
    //   }
    //   if(list.urltype == 0){
    //     this.$alert(list.content, list.title, {
    //       confirmButtonText: '确定',
    //       type:'info'
    //     });
    //     // alert(list.content)
    //   }
    //   if(list.urltype == 4){
    //     // alert('打开通知')
    //     let {href} = this.$router.resolve({path:'/detail',query:{nc:101034004,id:list.pingtaitype}})
    //     window.open(href,"_blank")
    //   }
    // }
  },
  computed: {
    fuwu1() {
      return this.fuwu.slice(
          (this.currentPage - 1) * 15,
          this.currentPage * 15
      );
    },
    // 水务新闻分页
    SwNews1() {
      return this.SwNews.slice((this.currentPage - 1) * 15, this.currentPage * 15);
    },
    //停水公告分页
    cutWater1() {
      return this.cutWaterlist.slice((this.currentPage - 1) * 15, this.currentPage * 15);
    },
  //  水质公告分页
    // waterNews1(){
    //   return this.waterNews.slice((this.currentPage - 1) * 15, this.currentPage * 15);
    // },
    waterAnnouncement1(){
      return this.waterAnnouncement.slice((this.currentPage - 1) * 15, this.currentPage * 15)
    },
    waterExcellent1(){
      return this.waterExcellent.slice((this.currentPage - 1) * 15, this.currentPage * 15)
    }
  },
  mounted() {
    // 执行获取停水数据方法
    this.cutWater();

    // 执行获取水务新闻的方法
    this.getNewsMsg();

    // 获取水质数据方法
    this.getWater();
    this.getOptimizeTheBusinessEnv()
    //
    this.getNotice()

    //
    this.getExcellent()

    //
    this.getpromise()

    // console.log(this.id);
    // console.log(this.show);
    // console.log(this.$route);
    // console.log($get)
    // console.log($post)
  },
};
</script>
<style>
.flex {
  display: flex;
}

.img {
  height: 200px;
  width: 1140px;
  /* border: 1px solid #000; */
  margin: 20px auto;
  background: url('../../public/img/20171121042343238.jpg') no-repeat center;
}

.img1 {
  height: 200px;
  width: 1140px;
  /* border: 1px solid #000; */
  margin: 20px auto;
  background: url('../../public/img/20171121042334086.jpg') no-repeat center;
}

.img2 {
  height: 200px;
  width: 1140px;
  /* border: 1px solid #000; */
  margin: 20px auto;
  background: url('../../public/img/20171121042352827.jpg') no-repeat center;
}

.bar {
  /* border: 1px solid #000; */
  width: 1140px;
  display: flex;
  margin: 5px auto;
}

.bar_left {
  width: 280px;
  height: auto;
  /*min-height: 150px;*/
  /*max-height: 300px;*/
  box-shadow: 0 0 10px #ccc;
  border-bottom: 2px solid #ccc;
}

.bar .bar_left .bar_left_title {
  /* border: 1px solid #000; */
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  /*background-color: #129ea3;*/
  color: white;
  background: url("../../public/img/l_bg.png") no-repeat;
}

.bar .bar_left ul {
  background-color: white;
  /* text-align: center; */
  padding: 10px 0;
}

.bar .bar_left .bar_left_item {
  text-align: center;
  margin: 15px auto;
  /* color: white; */
  border: 1px dotted #eee;
  background-color: white;
  border-radius: 20px;
  width: 200px;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
}

.bar .bar_left .bar_left_item:not(.active):hover {
  background-color: #1471d6;
  color: white;
  border: 1px dotted #f4f4f4;
}

.bar_right {
  /*flex: 1;*/
  width: 820px;
  height: auto;
  margin-left: 40px;
}

.bar_right .bar_right_title {
  justify-content: space-between;
  border-bottom: 2px solid #064a93;
  padding-bottom: 5px;
}

.bar_right .bar_right_title .title_gg {
  font-size: 16px;
  font-weight: bold;
}

.bar .bar_right_title .title_wz {
  font-size: 14px;
}

.bar .bar_right .bar_right_txt {
  padding: 15px;
}

.bar_right_txt .txt_news {
  margin: 30px auto;
  /*padding: 0 10px;*/
  background-color: #f4f4f4;
  padding: 0 15px;
  flex-wrap: wrap;
}

.bar_right_txt .bar_right_item {
   /*color: red;*/
  /*border: 1px solid #000;*/
  border-bottom: 1px dashed #f4f4f4;
  height: 32px;
  line-height: 32px;
  margin: 5px 0;
}

.bar_right_txt .bar_right_item:hover {
  color: darkred;
  cursor: pointer;
}

.bar_right_txt .txt_news .txt_news_hot {
  /* width: 120px; */
  height: 24px;
  line-height: 24px;
  background-color: #064a93;
  border-radius: 30px;
  padding: 6px 20px;
  margin: 10px;
  font-weight: bold;
  color: white;
}

.bar_right_txt .txt_news .txt_news_hot:first-child {
  background-color: #75b561;
}

.bar_right_txt .txt_news .txt_news_hot:hover {
  background-color: #75b561;
  cursor: pointer;
}

.txt_title_mini {
  color: red;
  font-size: 18px;
  font-weight: bold;
  border-left: 2px solid red;
  padding-left: 5px;
}

.bar_right_txt .txt_news .txt_news_title {
  font-size: 22px;
  color: #064a93;
  text-align: center;
  padding-top: 15px;
   /*border: 1px solid #000;*/
}

.bar_right_txt .txt_news img {
  width: 100%;
  margin: 20px auto;
  /*border: 1px solid #000;*/
}

#news_nav:hover {
  color: darkred;
  cursor: pointer;
}

.bar .bar_left .active {
  background-color: #064a93;
  color: white;
  border: 1px dotted #f4f4f4;
}

/* 分页样式修改 */
.el-pagination.is-background .el-pager li:not(.disabled).active {
  /* 修改背景颜色和字体颜色 */
  background-color: #064a93;
  color: #ffffff;
}

/* 鼠标悬浮样式修改 */
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  background-color: #064a93;
  color: white;
}

/* 首页按钮样式修改 */
.el-button {
  border: none;
  background-color: #f4f4f4;
}

/* 鼠标悬浮样式修改 */
.el-button:hover {
  color: white;
  /* border-color: #95cc83; */
  background-color: #064a93;
}

.el-pagination span:hover {
  background-color: #064a93;
  color: #ffff;
}

/* 按钮点击后事件 */
.el-button:focus {
  color: rgb(97, 97, 97);
  border-color: #f4f4f4;
  background-color: #f4f4f4;
}

.r_news {
  width: 750px;
  background-color: #f4f4f4;
  padding: 16px;
  margin: 10px auto;
}

.r_news .t_title {
  font-size: 22px;
  color: #064a93;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

.r_news .t_txt {
  /* border: 1px solid #000; */
  padding-left: 10px;
  line-height: 25px;
}

.d_title {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}

.d_time {
  background-color: #eee;
  padding: 5px 8px;
  margin: 8px 0;
}

.d_img{
  width: 540px;
  margin: 5px auto;
}

.d_img img{
  width: 540px;
  height: 303px;
}

a:hover {
  color: darkred;
}

/* 地图样式 */
.tsmap{
  text-align: center;
  width: 800px;
  margin-top: 30px;
  /*border: 1px solid black;*/
}
.tsmap .tsmap_title{
  font-size: 22px;
  font-weight: bold;
  color: black;
}
.tsmap .map{
  width: 800px;
  height: 400px;
  margin-top: 20px;
}
.water_notice{
  width: 800px;
  margin: 10px auto;
  /*border: 1px solid black;*/
}
.water_title{
  border-left: 4px solid black;
  padding: 6px 0;
  padding-left: 10px;
  font-size: 18px;
  font-weight: bold;
}
table{
  border-collapse: collapse;
}
td,th{
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
  padding: 10px 5px;
}
th{
  color: white;
  font-size: 12px;
  background: #0c91e5;
  /*border: 1px solid red;*/
}
td{
  font-size: 14px;
}
.water_table{
  margin: 10px auto;
  text-align: center;
  width: 100%;
  border: 1px solid #eee;
  box-shadow: 0px 5px 10px #ccc
}
.water_table:hover{
  box-shadow: 0px 5px 10px #a7b1c2;
}
.BMap_vectex {
   width: 0px !important;
   height: 0px !important;
}
.more{
  width: 800px;
  margin: 10px auto;
  flex-wrap: wrap;
  /*border: 1px solid black;*/
  justify-content: space-between;
}
.chuchang{
  width: 390px;
  height: 160px;
  font-size: 18px;
  color: white;
  text-align: center;
  line-height: 160px;
  background: url("../../public/img/shuichang42.png") no-repeat center rgba(0,0,0,0.5);
  background-size: 100%;
  background-blend-mode: multiply;
  border-radius: 10px;
}
.guanwang{
  width: 390px;
  height: 160px;
  font-size: 18px;
  color: white;
  text-align: center;
  line-height: 160px;
  background: url("../../public/img/guanwang42.png") no-repeat center rgba(0,0,0,0.5);
  background-size: 100%;
  background-blend-mode: multiply;
  border-radius: 10px;
}
.zonglan{
  width: 390px;
  height: 160px;
  font-size: 18px;
  color: white;
  text-align: center;
  line-height: 160px;
  background: url("../../public/img/shuichang42.png") no-repeat center rgba(0,0,0,0.5);
  background-size: 100%;
  background-blend-mode: multiply;
  border-radius: 10px;
}
.title_img{
  margin-top: 10px;
  width: 800px;
  height: 150px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  text-align: center;
  line-height: 150px;
  border-radius: 10px;
  background: url("../../public/img/t_1.jpg");
  background-size: 100%;
}
.title_img1{
  margin-top: 10px;
  width: 800px;
  height: 150px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  text-align: center;
  line-height: 150px;
  border-radius: 10px;
  background: url("../../public/img/t_2.jpg");
  background-size: 100%;
}
.title_img2{
  margin-top: 10px;
  width: 800px;
  height: 150px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  text-align: center;
  line-height: 150px;
  border-radius: 10px;
  background: url("../../public/img/t_3.jpg");
  background-size: 100%;
}
.title_img3{
  margin-top: 10px;
  width: 800px;
  height: 150px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  text-align: center;
  line-height: 150px;
  border-radius: 10px;
  background: url("../../public/img/t_4.jpg");
  background-size: 100%;
}
.title_img4{
  border: 1px solid black;
  margin-top: 10px;
  width: 800px;
  height: 150px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  text-align: center;
  line-height: 150px;
  border-radius: 10px;
  background: url("../../public/img/t_5.jpg") no-repeat;
  background-size: 100%;
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
  width: 58px;
}
.el-pagination span:hover{
  width: 58px;
  border-radius: 2px;
}
.constructionAddress{
  width: 800px;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  margin: 10px auto;
  background-color: #5697FB;
  color: white;
}
.construction_item{
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  width: 750px;
  margin: 10px auto;
}
.construction_item div{
  font-size: 16px;
}
</style>